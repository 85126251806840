<template>
  <div :class="margin">
    <UFormGroup
      :label="label"
      :ui="labelUi"
      :name="id"
      :size="size"
    >
      <USelectMenu
        :id="id"
        ref="selectMenu"
        :name="id"
        :multiple="multiple"
        v-bind="$attrs"
        :ui="ui"
        :popper="{ placement: 'bottom-start', offsetDistance: 5 }"
        :ui-menu="{
          container: containerUi,
          default: { trailingIcon: 'i-mdi-chevron-down' },
        }"
        :searchable-placeholder="$t('search')+'...'"
        @open="onOpen"
      >
        <template #label>
          <slot name="label" />
        </template>
        <template #option="{ option }">
          <slot
            name="option"
            :option="option"
          />
        </template>
      </USelectMenu>
    </UFormGroup>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id: string
    label?: string
    margin?: string
    multiple?: boolean
    customClasses?: string
    containerUi?: string
    ui?: object
    labelUi?: object
    size?: 'xs' | 'sm' | 'lg' | 'xl'
    noFocus?: boolean
  }>(),
  {
    multiple: false,
    label: '',
    margin: 'mb-2',
    containerUi: 'z-40 min-w-36',
    customClasses: '',
    size: 'sm',
    noFocus: false,
    ui: () => ({}),
    labelUi: () => ({})
  }
)

// todo: trying this to see if it will blur the input field when the menu is open
const selectMenu = ref()
function onOpen() {
  if (!props.noFocus) {
    return
  }

  const inputField = document.querySelector<HTMLInputElement>('input[id^="headlessui-combobox-input-"]')
  if (inputField) {
    const timeout = setTimeout(() => {
      inputField?.blur()
      inputField.tabIndex = -1
      clearTimeout(timeout)
    }, 0)
  }
}
</script>
